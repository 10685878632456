
import { useEffect, useRef, useState } from "react";
import { RECT } from "./section_data";
import monitorService from "../../Monitor/Shared/MonitorService";
import CircularProgress from '@mui/material/CircularProgress';

const SectionView = ({ boxSelected, time }) => {
    const [loadding, setLoadding] = useState(false);
    const canvasRef = useRef(null);

    function AdjustValueRecord(doc, record) {
        Object.keys(doc.value).map(k => {
            if (!record[k] || record[k] < doc.value[k].default) {
                doc.value[k].value = record[k] || '-';
                doc.value[k].y_default = doc.value[k].y;
            } else {
                const adjust = +(record[k] - doc.value[k].default) * doc.ratio;
                // kết quả = (bản ghi - đi giá trị thấp nhất của trạm) * tỉ lệ
                doc.value[k].value = Math.round(record[k] * 100) / 100;
                doc.value[k].y_default = doc.value[k].y;
                doc.value[k].h += adjust;
                doc.value[k].y -= adjust;
            }
        })
    }

    function DrawColumnValue(doc, ctx) {
        Object.values(doc.value).map((v, i) => {
            ctx.fillStyle = "#FF0000";
            ctx.fillRect(v.x, v.y, v.w, v.h);

            if (v.tx && v.ty) {
                ctx.fillStyle = '#a0ffa0';
                ctx.fillRect(v.x + v.tx, v.y_default + v.ty, 70, 30)
                ctx.font = "bold 20px Times New Roman";
                ctx.fillStyle = "#19860d";
                ctx.fillText(v.value, v.x + v.tx + 10, v.y_default + v.ty + 20);
            } else {
                ctx.fillStyle = '#a0ffa0';
                ctx.fillRect(v.x - 25, v.y_default + 10, 70, 30)
                ctx.font = "bold 20px Times New Roman";
                ctx.fillStyle = "#19860d";
                ctx.fillText(v.value, v.x - 15, v.y_default + 30);
            }
        });
    }

    function Draw(box_id_1, record1, box_id_2, record2, img) {
        const rect1 = RECT.find((r) => r.box_id === box_id_1);
        const doc1 = JSON.parse(JSON.stringify(rect1));
        AdjustValueRecord(doc1, record1)

        const rect2 = RECT.find((r) => r.box_id === box_id_2);
        const doc2 = JSON.parse(JSON.stringify(rect2));
        AdjustValueRecord(doc2, record2)

        const canvas = canvasRef.current
        const ctx = canvas.getContext("2d");
        const base_dir = "../images/khegiua";
        const image = new Image();
        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            // ctx.fillStyle = "#FF0000";
            // ctx.fillRect(100, 79, 10, 33);

            DrawColumnValue(doc1, ctx)
            DrawColumnValue(doc2, ctx)

            ctx.beginPath();
            if (boxSelected === "P8") ctx.moveTo(78, 211);
            if (boxSelected === "D5") ctx.moveTo(82, 219);
            if (boxSelected === "4M") ctx.moveTo(108, 300);
            ctx.lineTo(doc1.value.WP2.x + 2, doc1.value.WP2.y - 1);
            ctx.lineTo(doc1.value.WP3.x + 1, doc1.value.WP3.y - 1);
            ctx.lineTo(doc2.value.WP1.x + 1, doc2.value.WP1.y - 1);
            ctx.lineTo(doc2.value.WP3.x + 1, doc2.value.WP3.y - 1);
            if (boxSelected === "P8") ctx.lineTo(1700, 400);
            if (boxSelected === "D5") ctx.lineTo(1730, 414);
            if (boxSelected === "4M") ctx.lineTo(1767, 452);
            ctx.strokeStyle = "red";
            ctx.stroke();
        }
        image.src = `${base_dir}/${img}`;
    }

    useEffect(async () => {
        let box_id_1, box_id_2, img;
        if (boxSelected === "P8") {
            box_id_1 = "KDD0SN4G";
            box_id_2 = "D6QKHC5Y";;
            img = "mc12.png"
        } else if (boxSelected === "D5") {
            box_id_1 = "WU589RSL";
            box_id_2 = "QYOV7RB9";
            img = "mc22.png"
        } else if (boxSelected === "4M") {
            box_id_1 = "SPMXV9A0";
            box_id_2 = "YKEZCC6N";
            img = "mc32.png"
        }
        setLoadding(true)

        const data1 = await monitorService.getRecordByBox(box_id_1, { start: time.end, end: time.end })
        const data2 = await monitorService.getRecordByBox(box_id_2, { start: time.end, end: time.end })
        let record1, record2
        if (data1) record1 = data1?.data?.records[0] || []
        if (data2) record2 = data2?.data?.records[0] || []

        Draw(box_id_1, record1, box_id_2, record2, img);

        setLoadding(false)

    }, [boxSelected])

    const handleFullScreen = () => {
        const img = document.getElementById("myCanvas-Water");
        img.requestFullscreen();
    }

    return (
        <div className="SectionView">
            {loadding}
            {loadding && <CircularProgress />}
            {!loadding && 
            <div className="w100 end"><button onClick={handleFullScreen}>Toàn màn hình</button></div>
            }
            <canvas ref={canvasRef} id="myCanvas-Water" />
        </div>
    )
}

export default SectionView;