export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_CAMERA_URL =
	process.env.REACT_APP_CAMERA_URL;

const DEFAULT_API_URL = "https://api.dulieuthuyloivietnam.vn/api/";
export const FALLBACK_URL = process.env.REACT_APP_FALLBACK_URL || DEFAULT_API_URL;

export const ZONE_ID = "GI3K8ROF";

// 09398145 KKKXW2TW
export const ONE_SEC = 10e2;
export const ONE_MIN = 60e3;
export const ONE_HOUR = 3600e3;
export const ONE_DAY = 24 * ONE_HOUR;

export const SESSION_STORAGE = {
	TOKEN: "token",
	USER: "user",
	ZONE_ID: "zone_id",
};

export const LOCAL_STORAGE = {
	TOKEN: "token",
	USER: "user",
	ZONE_ID: "zone_id",
	GROUP_ID: "group_id",
};
export const BOXGROUP_ID = localStorage.getItem(
	LOCAL_STORAGE.GROUP_ID
); // Sông Lũy

export const ERRORS = {
	ZONE: [
		{
			message: "zone not found",
			translate: "Không tìm thấy khu vực.",
		},
		{
			message: "zone code existed",
			translate: "Mã khu vực đã tồn tại.",
		},
	],
	BOX: [
		{
			message: "box not found",
			translate: "Không tìm thấy trạm đo.",
		},
		{
			message: "box device existed",
			translate: "Thiết bị trạm đã tồn tại.",
		},
		{
			message: "box group not found",
			translate: "Không tìm thấy công trình.",
		},
		{
			message: "box group existed",
			translate: "Công trình đã tồn tại.",
		},
	],
	USER: [
		{
			message: "user not found",
			translate: "Không tìm thấy người dùng.",
		},
		{
			message: "username not found",
			translate: "Không tìm thấy tên tài khoản.",
		},
		{
			message: "Username existed",
			translate: "Tên tài khoản đã tồn tại.",
		},
	],
};

export const IMG_GROUP = [
	{
		id: "28087779", //cum da ton
		img: "da_ton",
		subdomain: "cumdaton.dulieuthuyloivietnam.vn",
	},
	{
		id: "09398145", //cum song may
		img: "song_may",
		subdomain: "songmay.dulieuthuyloivietnam.vn",
	},
	{
		id: "KKKXW2TW", //cong trinh song luy
		img: "song_luy",
		subdomain: "songluy.dulieuthuyloivietnam.vn",
	},
	{
		id: "25HZYZBP", //cong trinh ho da si
		img: "da_si",
		subdomain: "hodasi.dulieuthuyloivietnam.vn",
	},
	{
		id: "WFYOUGL1", //ho duong dong
		img: "duong_dong",
		subdomain: "hoduongdong.dulieuthuyloivietnam.vn",
	},
	{
		id: "79R4HBQI", //doi ong tuong
		img: "doi_ong_truong",
		subdomain: "doiongtruong.dulieuthuyloivietnam.vn",
	},
];


export const TYPE_BOX_MAP = {
    WA: { label: 'Đo mực nước', code: 'WA' },
    WP: { label: 'Đo thấm', code: 'WP' },
    RA: { label: 'Đo mưa', code: 'RA' },
    DR: { label: 'Đo độ mở cống', code: 'DR' },
    SA: { label: 'Đo độ mặn', code: 'SA' },
	AUDIO: { label: 'Loa cảnh báo', code: 'AUDIO'},
	ICI: { labe: 'Đo dịch Động', code: 'ICI' },
	MEI: { label: 'Đo nghiêng', code: 'MEI'},
	PZ: { label: 'Mực nước ngầm', code: 'PZ'}
}

export const ID_GROUPS = {
	DOT: "79R4HBQI",
	KG: "EHFJZIUT",
	SL: "KKKXW2TW",
	DD: "WFYOUGL1",
	CM: "IXC8QY8W"
}