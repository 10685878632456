import React from "react";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  TimeScale
} from 'chart.js';
import { format } from "date-fns";
import zoomPlugin from 'chartjs-plugin-zoom';
import { BOXGROUP_ID, ID_GROUPS } from "../../../../Utils/const";

const time_second = (d) => format(d, "HH:mm:ss");

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  TimeScale,
  zoomPlugin
);
// MultiLineChart
class MultiLineChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.destroy();
    this.DrawChartFunc();
  }

  componentDidMount() {
    this.DrawChartFunc();
  }

  DrawChartFunc = () => {
    let max;
    if(this.props?.data.box_id === 'R8ZLNSJX') max = 5;
    else if(this.props?.data.box_id === 'WK34ZRT9') max = 10;

    let min;
    if(this.props?.data.box_id === 'Z00PSBWY') min = 0;
    
    let text = 'Độ mở cửa (m)';
    if(this.props?.data.box_id === 'Z00PSBWY') text = 'Độ mở cửa (mm)';

    this.myChart = new Chart(this.canvasRef.current, {
      type: 'line',
      options: {
        maintainAspectRatio: false,
        type: 'line',
        responsive: true,
        plugins: {
          tooltip : {
            titleFont: {
              weight: 'bold',
              size: '13px'
            },
            bodyFont: {
              weight: 'bold'
            }
          },
          legend: {
            position: 'bottom',
            labels: {
              font: {
                weight: 'bold',
                size: '11px',
                family: "Roboto, Helvetica, Arial, sans-serif !important",
              }
            },
          },
          // zoom: {
          //   pan: {
          //     enabled: true,
          //     mode: 'xy',
          //   },
          //   zoom: {
          //     wheel: {
          //       enabled: true,
          //     },
          //     pinch: {
          //       enabled: true
          //     },
          //     mode: 'xy',
          //     onZoomComplete({ chart }) {
          //       chart.update('none');
          //     }
          //   }
          // }
        },
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            min: BOXGROUP_ID !== ID_GROUPS.KG ? null: min,
            max: BOXGROUP_ID === ID_GROUPS.KG ? 100 : max,
            title: {
              text,
              display: true,
              font: {
                weight: 'bold',
                size: '11px',
                family: "Roboto, Helvetica, Arial, sans-serif !important",
              }
            },
            ticks: {
              font: {
                size: '11px !important',
                weight: null,
                family: "Roboto, Helvetica, Arial, sans-serif !important",
              },
            }
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            min: 0,
            max: null,
            ticks: {
              font: {
                size: '11px !important',
                weight: null,
                family: "Roboto, Helvetica, Arial, sans-serif !important",
              },
            },
            title: {
              text: 'Lưu lượng (m3/s)',
              display: true,
              font: {
                weight: 'bold',
                size: '11px',
                family: "Roboto, Helvetica, Arial, sans-serif !important",
              }
            },
            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
          x: {
            ticks: {
              callback: function (val) {
                const value = this.getLabelForValue(val)
                const change = value.slice(0, 2)
                return change + 'h'
              },
              font: {
                size: '11px !important',
                weight: null,
                family: "Roboto, Helvetica, Arial, sans-serif !important",
              },
              autoSkip: true,
              maxTicksLimit: 24,
              min: 0,
            }
          }
        }
      },
      data: {
        labels: this.props.data.labels.map(l => time_second(l)),
        datasets: this.props.data.datasets
      }
    });
  }

  onReset = () => {
    this.myChart.resetZoom();
  }

  render() {
    return <div style={{ width: '100%', height: this.props.height || '30vh' }}>
      {/* <button onClick={() => this.onReset()}>reset</button> */}
      <canvas style={{ width: '100%', height: '100%' }} ref={this.canvasRef} id="myCanvas-MultiLine"/>
    </div>
  }
}
export default MultiLineChart