import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Statistical from "./Components/Statistical/Statistical";
import CameraControl from "./Components/Camera/Camera";
import Chart from "./Components/Chart-Right/Chart";
import Map from "./Components/Map/Map";
import dashboardSevice from "./Shared/DashboardService";
import sharedService from "../../../Services/SharedService";
import { BOXGROUP_ID, ID_GROUPS } from "../../../Utils/const";
import { CHECK_EMPTY_STRING } from "../../../Utils/string";
import { Button } from "@mui/material";
import AlertConfirm from "../../Components/AlertNoti/AlertConfirm";
import useAudio from "../../Hooks/useAudio";
import axios from "axios";
import DashBoardDOT from "./Components/DashboardDOT";
import { BOX_DOT2 } from "../../Components/App/data";

const DashBoard = () => {
    const groupId = BOXGROUP_ID;
    const [group, setGroup] = useState();
    const [cameras, setCameras] = useState([]);
    const [center, setCenter] = useState([]);
    const [records, setRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const id = window.localStorage.getItem('group_id')

    // function SubdomainIsDemo() {
    //     // const url = 'https://dulieuthuyloivietnam.vn/app/help'
    //     const url = window.location.href;
    //     if(url.includes('demo')) return true;
    //     else return false
    // }

    useEffect(() => {
        if (!CHECK_EMPTY_STRING(groupId)) {
            dashboardSevice.getBoxGroup(groupId).then(
                res => {
                    const box_group = res.data;
                    if (box_group.cameras && box_group.cameras.length !== +0) {
                        setCameras(box_group.cameras);
                    }
                    if(ID_GROUPS.DOT === BOXGROUP_ID) box_group?.boxs?.push(BOX_DOT2)
                    setGroup(box_group)
                    const center = Object.values(box_group.center);
                    setCenter(center);
                    setIsLoading(false);
                }
            ).catch(err => console.log(err));

            sharedService.listRecordBoxGroup("RA").then(
                res => {
                    const { data } = res;
                    setRecords(data);
                }
            )
        }
    }, [])
    // audio test
    const urlAudio = require("../../../Asset/warningAudio/warning.mp3")
    const audio = useAudio(urlAudio, { volume: 0.8, playbackRate: 2.5 })
    const [modalConfirm, setModalConfirm] = useState({ open: false, message: "", severity: "" })
    const [intervalId, setIntervalId] = useState(0)
    const [listUsers, setListUsers] = useState([]);

    useEffect(() => {
        sharedService
            .getListUser()
            .then((res) => {
                if (res?.data.length !== 0) {
                    setListUsers(res?.data);
                }
            })
            .catch((err) => console.log(err));
    }, [])
    const userEnoughRole = async () => {
        const user = listUsers?.filter(item => item?.groups?.includes(id))
        Promise.all(user.map(u => {
            if (u.zalo_id) {
                axios.post(`https://notify.dulieuthuyloivietnam.vn/api/v1/service/zalo/${u.phone}`, { message: 'test' })
            }
        }))
    }

    const testAudio = () => {
        audio.play()
        setModalConfirm({
            open: true,
            message: `Mực nước thượng lưu vượt ngưỡng cảnh báo!`,
            severity: 'error'
        })
        const newId = setInterval(() => {
            userEnoughRole()
        }, 5000)
        setIntervalId(newId)
    }

    const handleCloseConfirm = () => {
        setModalConfirm({ open: false, severity: "", message: "" })
        audio.pause()
        clearInterval(intervalId)
        setIntervalId(0)
    }

    return (
        <>
            {groupId === ID_GROUPS.DOT ? <DashBoardDOT groupId={groupId} isLoading={isLoading} records={records} group={group} center={center} />
                : <>
                    <AlertConfirm open={modalConfirm.open} message={modalConfirm.message} severity={modalConfirm.severity} handleClose={handleCloseConfirm} />

                    <Grid container columns={12}>
                        <Grid
                            item
                            xs={12}
                            lg={4}
                            sx={{
                                marginTop: { xs: '20px', md: '0px' },
                                display: { xs: 'block', lg: 'none' },
                            }}>
                            <Statistical data={records} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={4}
                            sx={{
                                marginTop: { xs: '20px', lg: '0px' }
                            }}
                        >
                            <CameraControl cameras={cameras} isLoading={isLoading} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={4}
                            sx={{
                                display: { xs: 'none', lg: 'block' }
                            }}>
                            <Statistical data={records} />
                        </Grid>
                        <Grid item xs={12} lg={4} sx={{ maxWidth: '1000px', minWidth: '260px', width: '200px', marginTop: { xs: '20px', lg: '0px' } }}>
                            <Chart />
                            {/* {
                                id === '79R4HBQI' && SubdomainIsDemo() ? <Button onClick={testAudio} variant='contained' color="warning">Test am thanh</Button> : null
                            } */}
                            <Map group={group} center={center} isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </>
            }
        </>
    )
}

export default DashBoard;